import React from 'react'
import { Card, Button, Col, Row } from 'react-bootstrap'
import Header from '../components/Header'
import Footer from '../components/Footer'
import 'components/css/blog-section.css'
import blogsStaticData from '../data/static/BlogsData'
import Seo from '../components/Seo'
const Blog = () => (
  <div>
    <Seo
      title="Digipay PH | Blogs"
      description="Digipay is a digital payments and financial services platform with
a mobile wallet, an industry leading biller ecosystem,
and an interoperable backend."
      keywords="digipay.ph/blogs/,Digipay, Stories, Digipay Stories, Blogs, Post, Blog Post, Digipay Blogs"
    />
    <Header></Header>
    <div className="digipay-stories">
      <h1 align="center" id="font-weight-bold padding-bottom-md">
        DIGIPAY STORIES
        <hr />
      </h1>
      <Row {...componentProps.cardItemListContainerProps}>
        {blogsStaticData.map((value, index) => {
          return (
            <Col {...componentProps.cardItemWrapperProps} key={index}>
              <Card className="digipay-stories__story-content">
                <Card.Img
                  {...componentProps.cardItemImageProps}
                  src={value.cardImageSource}
                  alt={value.cardImageAlt}
                />
                <Card.Body>
                  <Card.Title {...componentProps.cardItemTitleProps}>
                    {value.cardTitleLineOne}
                    <br /> {value.cardTitleLineTwo}
                  </Card.Title>
                  <Card.Text {...componentProps.cardItemText}>
                    {value.cardDescription}
                  </Card.Text>
                  <Button
                    {...componentProps.readMoreButton}
                    href={value.readMoreButtonHref}
                  >
                    Read More
                  </Button>
                </Card.Body>
              </Card>
            </Col>
          )
        })}
      </Row>
    </div>
    <Footer></Footer>
  </div>
)

const componentProps = {
  cardItemWrapperProps: {
    className: 'digipay-stories__story',
    align: 'center',
    md: 4,
  },
  cardItemImageProps: {
    variant: 'top',
  },
  cardItemTitleProps: {
    className: 'digipay-stories__story-title',
  },
  cardItemText: {
    className: 'digipay-stories__story-description ',
  },
  readMoreButton: {
    variant: 'danger',
    className: 'digipay-stories__btn-read-more',
  },
}
export default Blog
