import React from 'react'

import Feature from 'images/blogs/blog-18.png'
import Tips from 'images/blogs/blog-2.jpg'
import Promo from 'images/promo.jpg'
import Ways from 'images/blogs/blog-3.jpg'
import Summer from 'images/blogs/blog-4.jpg'
import Help from 'images/blogs/blog-5.jpg'
import Important from 'images/blogs/blog-6.png'
import Business from 'images/blogs/blog-7.png'
import Digitindahan from 'images/blogs/blog-8.png'
import Types from 'images/blogs/blog-9.png'
import Guide from 'images/blogs/blog-10.png'
import Benefits from 'images/blogs/blog-11.png'
import Mistakes from 'images/blogs/blog-12.png'
import Pasko from 'images/blogs/blog-13.png'
import Suki from 'images/blogs/blog-14.png'
import GoodBusiness from 'images/blogs/blog-15.png'
import Love from 'images/blogs/blog-16.png'
import Language from 'images/blogs/blog-17.png'
import PeraPadala from 'images/blogs/blog-22.png'

const blogsStaticData = [
  {
    cardImageSource: Feature,
    cardImageAlt: 'guide-bg',
    cardTitleLineOne: '6 USEFUL FEATURES IN THE',
    cardTitleLineTwo: 'DIGIPAY APP',
    cardDescription:
      'Nasubukan mo na bang gamitin ang features na ito, ka-Digipay?',
    readMoreButtonHref: '/stories/DigipayAppFeatures',
  },
  {
    cardImageSource: Language,
    cardImageAlt: 'guide-bg',
    cardTitleLineOne: 'DIGIPAY AGENTS IN 5 LOVE',
    cardTitleLineTwo: 'LANGUAGES',
    cardDescription:
      'Paano mo ipinapakita ang iyong pagmamahal sa iyong mga suki, ka-Digipay?',
    readMoreButtonHref: '/stories/DigipayAgentsIn5LoveLanguages',
  },
  {
    cardImageSource: Love,
    cardImageAlt: 'guide-bg',
    cardTitleLineOne: 'DIGIPAY AGENTS ANSWER WHY THEY',
    cardTitleLineTwo: 'LOVE DIGIPAY',
    cardDescription: 'Ikaw ka-Digipay, anong pinaka-gusto mo sa negosyong ito?',
    readMoreButtonHref: '/stories/WhyAgentsLoveDigipay',
  },
  {
    cardImageSource: GoodBusiness,
    cardImageAlt: 'guide-bg',
    cardTitleLineOne: 'WHY IS DIGIPAY A GOOD BUSINESS',
    cardTitleLineTwo: 'THIS 2020?',
    cardDescription:
      'Kung pinag-iisipan mong mag-negosyo this year, basahin mo ‘to ka-Digipay.',
    readMoreButtonHref: '/stories/WhyIsDigipayAGoodBusiness',
  },
  {
    cardImageSource: Suki,
    cardImageAlt: 'guide-bg',
    cardTitleLineOne: '4 DIGIPAY SUKI YOU PROBABLY',
    cardTitleLineTwo: 'KNOW',
    cardDescription: 'Kung suki ka ng Digipay, ikaw naman ngayon ang bida!',
    readMoreButtonHref: '/stories/4DigipaySukiYouProbablyKnow',
  },
  {
    cardImageSource: Pasko,
    cardImageAlt: 'guide-bg',
    cardTitleLineOne: 'TIPS PARA MAGING EXCITING ANG PASKO',
    cardTitleLineTwo: 'WITH DIGIPAY',
    cardDescription: "Alamin kung paano maa-achieve 'yan as a Digipay Agent.",
    readMoreButtonHref: '/stories/TipsParaMagingExcitingAngPasko',
  },
  {
    cardImageSource: Mistakes,
    cardImageAlt: 'guide-bg',
    cardTitleLineOne: '4 MISTAKES TO AVOID AS',
    cardTitleLineTwo: 'A DIGIPAY AGENT',
    cardDescription: 'Iwasan mong gawin ang mga ito, ka-Digipay.',
    readMoreButtonHref: '/stories/MistakesToAvoidAsADigipayAgent',
  },
  {
    cardImageSource: Benefits,
    cardImageAlt: 'guide-bg',
    cardTitleLineOne: '4 BENEFITS OF GOING PAPERLESS',
    cardTitleLineTwo: 'WITH DIGIPAY',
    cardDescription:
      'Alam mo bang paperless ang lahat ng transaction sa Digipay?',
    readMoreButtonHref: '/stories/BenefitsOfGoingPaperless',
  },
  {
    cardImageSource: Guide,
    cardImageAlt: 'guide-bg',
    cardTitleLineOne: 'DIGIPAY GUIDE',
    cardTitleLineTwo: 'FROM A-Z',
    cardDescription:
      'Lahat ng kailangan mong malaman sa Digipay app, narito na!',
    readMoreButtonHref: '/stories/GuideFromAtoZ',
  },
  {
    cardImageSource: Types,
    cardImageAlt: 'important-bg',
    cardTitleLineOne: '5 TYPES OF DIGIPAY',
    cardTitleLineTwo: 'AGENTS',
    cardDescription: 'Baka isa ka sa kanila, ka-Digipay!',
    readMoreButtonHref: '/stories/FiveTypesOfAgents',
  },
  {
    cardImageSource: Digitindahan,
    cardImageAlt: 'important-bg',
    cardTitleLineOne: 'HOW TO TURN YOUR SARI-SARI STORE',
    cardTitleLineTwo: 'INTO A DIGITINDAHAN',
    cardDescription:
      'Mayroon ka bang sari-sari store? Gawin mo na itong authorized payment center with Digipay!',
    readMoreButtonHref: '/stories/Digitindahan',
  },
  {
    cardImageSource: Business,
    cardImageAlt: 'important-bg',
    cardTitleLineOne: 'TIPS FOR ASPIRING BUSINESS',
    cardTitleLineTwo: 'OWNERS',
    cardDescription:
      'Nagbabalak ka bang magkaroon ng sariling negosyo? Read these tips.',
    readMoreButtonHref: '/stories/BusinessOwnerTips',
  },
  {
    cardImageSource: Important,
    cardImageAlt: 'important-bg',
    cardTitleLineOne: '5 REASONS WHY DIGIPAY',
    cardTitleLineTwo: 'IS IMPORTANT',
    cardDescription: 'Kailangan mong masubukan ang Digipay ngayon!',
    readMoreButtonHref: '/stories/why-digipay',
  },
  {
    cardImageSource: Help,
    cardImageAlt: 'help-bg',
    cardTitleLineOne: '6 TIPS FOR DIGIPAY',
    cardTitleLineTwo: 'AGENTS',
    cardDescription: 'Alamin kung paano maging mas mahusay na Digipay agent!',
    readMoreButtonHref: '/stories/6-tips-for-digipay-agents',
  },
  {
    cardImageSource: Summer,
    cardImageAlt: 'summer-bg',
    cardTitleLineOne: 'PAANO I-ENJOY ANG SUMMER WITH',
    cardTitleLineTwo: 'DIGIPAY',
    cardDescription:
      'Gamitin ang Digipay para sa mas pinasayang experience ngayong summer',
    readMoreButtonHref: '/stories/paano-ienjoy-ang-summer-with-digipay',
  },
  {
    cardImageSource: Ways,
    cardImageAlt: 'ways-bg',
    cardTitleLineOne: '6 NA PARAAN KUNG PAANO KUMITA GAMIT ANG DIGIPAY APP',
    cardTitleLineTwo: 'DIGIPAY',
    cardDescription:
      'Gamitin ang Digipay para sa mas pinasayang experience ngayong summer',
    readMoreButtonHref:
      '/stories/6-na-paraan-kung-paano-kumita-gamit-ang-digipay-app',
  },
  {
    cardImageSource: Tips,
    cardImageAlt: 'tips-bg',
    cardTitleLineOne: 'MGA TIPS SA PAGGAMIT',
    cardTitleLineTwo: 'NG DIGIPAY APP',
    cardDescription: 'Alamin ang mga dapat gawin sa paggamit ng Digipay!',
    readMoreButtonHref: '/stories/mga-tips-sa-paggamit-ng-digipay-app',
  },
  {
    cardImageSource: Promo,
    cardImageAlt: 'promo-bg',
    cardTitleLineOne: '5 REASONS WHY YOU SHOULD DOWNLOAD',
    cardTitleLineTwo: 'AND SIGN UP WITH',
    cardDescription:
      'Still need convincing? Click here to see five reasons why you should start using Digipay today!',
    readMoreButtonHref:
      '/stories/5-reasons-why-you-should-download-and-sign-up-with-digipay',
  },
  {
    cardImageSource: PeraPadala,
    cardImageAlt: 'tips-bg',
    cardTitleLineOne: 'PERA PADALA?',
    cardTitleLineTwo: ' #DIGIPADALANAYAN',
    cardDescription:
      'Alamin kung paano at saan maaaring magpadala o tumanggap ng pera gamit ang Digipadala!',
    readMoreButtonHref:
      '/stories/PeraPadala',
  },
]

export default blogsStaticData
